import React from "react";
import Duplicate from "./Duplicate";

const App = () => {
  return (
    <div>
      <div
        className="owl-carousel image-carousel carousel-widget"
        data-margin="20"
        data-nav="true"
        data-pagi="false"
        data-items-xs="2"
        data-items-sm="3"
        data-items-md="4"
        data-items-lg="5"
      >
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1602342154549-3ced44f38f91?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1593872967780-1b0c15e04f7a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1600184050830-9a7e391cdf92?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1602342154549-3ced44f38f91?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1602342154549-3ced44f38f91?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1602342154549-3ced44f38f91?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt="Image 1"
            />
          </a>
        </div>
        <div className="oc-item">
          <a href="#">
            <img
              src="https://images.unsplash.com/photo-1602342154549-3ced44f38f91?ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              alt="Image 1"
            />
          </a>
        </div>
      </div>
      <Duplicate />
    </div>
  );
};
export default App;
